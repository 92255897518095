import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

export const TopUpBtn = () => {
  return (
    <Button
      variant="contained"
      endIcon={<Add />}
      sx={{ fontSize: 18, lineHeight: 1, borderRadius: 20, height: 40, px: 2.5 }}
    >
      Top up
    </Button>
  );
};
