import { Button, alpha, useTheme } from "@mui/material";
import { Apple } from "@mui/icons-material";

export const ApplePayBtn = () => {
  const theme = useTheme();

  return (
    <Button
      startIcon={<Apple sx={{ mr: -0.5 }} />}
      sx={{
        bgcolor: "white",
        color: "#161A2F",
        lineHeight: 1,
        "&:hover": {
          bgcolor: alpha(theme.palette.common.white, 0.8),
        },
      }}
    >
      Pay
    </Button>
  );
};
