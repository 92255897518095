import { Bolt } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export const YourBalance = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
      <Typography sx={{ color: "#A3AED0", lineHeight: 1 }}>Your Balance</Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2.5 }}>
        <Typography sx={{ fontSize: 48, fontWeight: 600, lineHeight: 1 }}>50</Typography>

        <Box
          sx={{
            bgcolor: "#F9AA4B",
            display: "inline-flex",
            width: 40,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 40,
          }}
        >
          <Bolt sx={{ color: "white", fontSize: 32 }} />
        </Box>
      </Box>
    </Box>
  );
};
