import React, { SVGProps } from "react";

export const HooraySvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="none" viewBox="0 0 100 100" {...props}>
      <path
        fill="url(#paint0_radial_14335_16293)"
        d="M49.688 92.813C27.89 92.813 4.374 79.14 4.374 49.14c0-30 23.516-43.672 45.313-43.672 12.109 0 23.28 3.984 31.562 11.25C90.234 24.687 95 35.937 95 49.14c0 13.203-4.766 24.375-13.75 32.343-8.281 7.266-19.531 11.329-31.563 11.329z"
      ></path>
      <path
        fill="#EB8F00"
        d="M87.102 23.18c4.164 6.718 6.335 14.718 6.335 23.617 0 13.203-4.765 24.375-13.75 32.344-8.28 7.265-19.53 11.328-31.562 11.328-14.11 0-28.938-5.742-37.64-17.922 8.406 13.797 24.21 20.266 39.203 20.266 12.03 0 23.28-4.063 31.562-11.329C90.234 73.516 95 62.344 95 49.141c0-9.953-2.71-18.797-7.898-25.961z"
      ></path>
      <path
        fill="#422B0D"
        d="M48.961 62.828c-9.938 0-19.172-.836-26.883-2.258-1.914-.351-3.601 1.328-3.21 3.235 2.562 12.53 14.898 22.82 30.085 22.82 16.617 0 27.805-10.625 30.125-22.867.36-1.89-1.328-3.54-3.219-3.188-7.718 1.422-16.96 2.258-26.898 2.258z"
      ></path>
      <path
        fill="#422B0D"
        stroke="#422B0D"
        strokeMiterlimit="10"
        strokeWidth="0.078"
        d="M69.984 44.773a4.53 4.53 0 00-1.03-1.53c-.454-.446-1-.829-1.65-1.157-.515-.258-1.093-.5-1.718-.719v-6.57c.117.039.242.07.351.125.399.195.735.476 1.008.828.274.352.485.773.625 1.258.055.195.102.39.133.594.094.57.563 1.007 1.14 1.007h.032a1.21 1.21 0 001.195-1.398c-.164-1.125-.539-2.055-1.117-2.79-.797-1.015-1.922-1.624-3.367-1.827v-1.727a1.134 1.134 0 00-2.266 0v1.781a6.061 6.061 0 00-1.601.47 4.588 4.588 0 00-1.47 1.054c-.398.437-.71.953-.937 1.539-.218.586-.328 1.242-.328 1.977 0 .773.125 1.453.375 2.039.25.586.61 1.109 1.07 1.562a6.514 6.514 0 001.672 1.172c.376.187.79.367 1.22.531v6.774l-.009.007a3.123 3.123 0 01-1.492-.687c-.343-.29-.617-.672-.836-1.148a3.418 3.418 0 01-.242-.813c-.101-.563-.555-1-1.133-1h-.117c-.71 0-1.25.64-1.133 1.336.07.43.18.82.328 1.18.29.703.665 1.28 1.141 1.742.477.46 1.023.812 1.64 1.054a7.32 7.32 0 001.844.454v1.359a1.134 1.134 0 002.266 0v-1.398a7.169 7.169 0 001.813-.461 4.934 4.934 0 001.578-1.032 4.54 4.54 0 001.008-1.539c.234-.593.359-1.273.359-2.023.008-.766-.11-1.445-.352-2.024zm-7.734-5.046a2.64 2.64 0 01-.656-.922 2.965 2.965 0 01-.211-1.157c.008-.93.281-1.664.82-2.21.29-.297.672-.508 1.117-.641v5.648c-.43-.218-.789-.46-1.07-.718zm5.43 8.375a2.727 2.727 0 01-.72.96 3.121 3.121 0 01-1.14.602c-.07.023-.148.031-.226.047v-5.867c.617.289 1.117.601 1.476.945.586.563.875 1.234.86 2.023 0 .493-.086.915-.25 1.29z"
      ></path>
      <path
        fill="#05E005"
        d="M34.797 68.578v16.524c0 7.43 6.023 13.445 13.445 13.445h2.563c7.43 0 13.445-6.024 13.445-13.445V68.46c-14.852 2.906-25.914.937-29.453.117z"
      ></path>
      <path
        fill="#05E005"
        d="M48.953 66.43c-4.836 0-9.594-.196-14.156-.578v2.726c3.547.82 14.601 2.79 29.46-.117v-2.719c-4.913.445-10.062.688-15.304.688z"
      ></path>
      <path
        fill="#AB3F2E"
        d="M48.953 66.43c-4.836 0-9.594-.196-14.156-.578v2.726c3.547.82 14.601 2.79 29.46-.117v-2.719c-4.913.445-10.062.688-15.304.688z"
        opacity="0.3"
      ></path>
      <path
        fill="#404040"
        d="M55.445 84.586a4.53 4.53 0 00-1.031-1.531c-.453-.446-1-.828-1.648-1.157-.516-.257-1.094-.5-1.72-.718v-6.57c.118.038.243.07.352.124.399.196.735.477 1.008.829.274.351.485.773.625 1.257.055.196.102.39.133.594.094.57.563 1.008 1.14 1.008h.032a1.21 1.21 0 001.195-1.399c-.164-1.117-.539-2.046-1.117-2.789-.797-1.015-1.922-1.625-3.367-1.828V70.68a1.134 1.134 0 00-2.266 0v1.78a6.062 6.062 0 00-1.601.47 4.587 4.587 0 00-1.47 1.054 4.871 4.871 0 00-.937 1.54c-.218.585-.328 1.242-.328 1.976 0 .773.125 1.453.375 2.04.25.585.61 1.108 1.07 1.562.462.453 1.016.843 1.672 1.171.375.188.79.368 1.22.532v6.773l-.009.008a3.124 3.124 0 01-1.492-.688c-.344-.289-.617-.671-.836-1.148a3.418 3.418 0 01-.242-.813c-.11-.562-.562-1-1.14-1h-.118c-.71 0-1.25.641-1.133 1.336.07.43.18.82.329 1.18.289.703.664 1.281 1.14 1.742.477.461 1.024.813 1.64 1.055a7.32 7.32 0 001.845.453v1.36a1.134 1.134 0 002.265 0v-1.399a7.169 7.169 0 001.813-.46 4.934 4.934 0 001.578-1.032 4.54 4.54 0 001.008-1.54c.234-.593.359-1.273.359-2.023.008-.765-.11-1.437-.344-2.023zm-7.742-5.047a2.64 2.64 0 01-.656-.922 2.965 2.965 0 01-.211-1.156c.008-.93.281-1.664.82-2.211.29-.297.672-.508 1.117-.64v5.648c-.43-.219-.789-.461-1.07-.719zm5.43 8.383a2.728 2.728 0 01-.719.96 3.12 3.12 0 01-1.14.602c-.07.024-.15.032-.227.047v-5.867c.617.29 1.117.602 1.476.945.586.563.875 1.235.86 2.024 0 .484-.086.906-.25 1.289z"
      ></path>
      <path
        fill="#422B0D"
        d="M23.242 25.617c-1.305 1.117-3.242-.492-2.023-2.023.906-1.11 2.125-2.164 3.453-2.961 3.578-2.305 7.984-3.047 11.742-2.305 1.914.414 1.328 2.852-.422 2.86-4.43-.063-9.047 1.43-12.75 4.43zm52.946 0c1.304 1.117 3.242-.492 2.023-2.023-.906-1.11-2.125-2.164-3.453-2.961-3.578-2.305-7.984-3.047-11.742-2.305-1.914.414-1.328 2.852.422 2.86a19.96 19.96 0 0112.75 4.43z"
      ></path>
      <path
        fill="#422B0D"
        stroke="#422B0D"
        strokeMiterlimit="10"
        strokeWidth="0.078"
        d="M39.602 44.773a4.53 4.53 0 00-1.032-1.53c-.453-.446-1-.829-1.648-1.157-.516-.258-1.094-.5-1.719-.719v-6.57c.117.039.242.07.352.125a2.87 2.87 0 011.007.828c.274.352.485.773.625 1.258.055.195.102.39.133.594.094.57.563 1.007 1.14 1.007h.032a1.21 1.21 0 001.195-1.398c-.164-1.117-.539-2.047-1.117-2.79-.797-1.015-1.922-1.624-3.367-1.827v-1.727a1.134 1.134 0 00-2.266 0v1.781a6.062 6.062 0 00-1.601.47 4.588 4.588 0 00-1.469 1.054c-.398.437-.71.953-.937 1.539-.22.586-.328 1.242-.328 1.977 0 .773.125 1.453.375 2.039.25.586.609 1.109 1.07 1.562a6.513 6.513 0 001.672 1.172c.375.187.789.367 1.218.531v6.774l-.007.007a3.124 3.124 0 01-1.493-.687c-.343-.29-.617-.672-.835-1.148a3.418 3.418 0 01-.243-.813c-.101-.563-.554-1-1.133-1h-.117c-.71 0-1.25.64-1.133 1.336.07.43.18.82.329 1.18.289.703.664 1.28 1.14 1.742.477.46 1.024.812 1.64 1.054a7.32 7.32 0 001.845.454v1.359a1.133 1.133 0 002.265 0v-1.398a7.169 7.169 0 001.813-.461 4.935 4.935 0 001.578-1.032 4.54 4.54 0 001.008-1.539c.234-.593.36-1.273.36-2.023.007-.766-.11-1.445-.352-2.024zm-7.735-5.046a2.64 2.64 0 01-.656-.922A2.965 2.965 0 0131 37.648c.008-.93.281-1.664.82-2.21.29-.297.672-.508 1.117-.641v5.648a4.817 4.817 0 01-1.07-.718zm5.43 8.375a2.728 2.728 0 01-.719.96 3.121 3.121 0 01-1.14.602c-.07.023-.149.031-.227.047v-5.867c.617.289 1.117.601 1.476.945.586.563.875 1.234.86 2.023 0 .493-.086.915-.25 1.29z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_14335_16293"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(44.5 0 0 44.5 49.688 49.14)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#FDE030"></stop>
          <stop offset="0.919" stopColor="#F7C02B"></stop>
          <stop offset="1" stopColor="#F4A223"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
};
