import { Box, Button, alpha, useTheme } from "@mui/material";

import { GoogleLogoSvg } from "./GoogleLogoSvg";

export const GooglePayBtn = () => {
  const theme = useTheme();

  return (
    <Button
      startIcon={
        <Box sx={{ mr: -0.5, display: "inline-flex" }}>
          <GoogleLogoSvg width={16} />
        </Box>
      }
      sx={{
        borderRadius: 10,
        bgcolor: "white",
        color: "#161A2F",
        lineHeight: 1,
        "&:hover": {
          bgcolor: alpha(theme.palette.common.white, 0.8),
        },
      }}
    >
      Pay
    </Button>
  );
};
