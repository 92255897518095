import { Button, alpha } from "@mui/material";
import { Star } from "@mui/icons-material";

export const SubscribeBtn = () => {
  return (
    <Button
      variant="contained"
      startIcon={<Star />}
      sx={{
        fontSize: 16,
        bgcolor: "#FFBE00",
        color: "#161A2F",
        "&:hover": {
          bgcolor: alpha("#FFBE00", 0.8),
        },
        borderRadius: 10,
        px: 2.5,
      }}
    >
      Click to Subscribe
    </Button>
  );
};
