import React, { SVGProps } from "react";

export const ClaimSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26" {...props}>
      <path
        fill="#161A2F"
        d="M13.297.388c6.904 0 12.5 5.596 12.5 12.5 0 6.903-5.596 12.5-12.5 12.5-6.903 0-12.5-5.597-12.5-12.5 0-6.904 5.597-12.5 12.5-12.5zm-.883 8.08l-3.537 3.536a1.25 1.25 0 000 1.767l3.537 3.537a1.25 1.25 0 001.767 0l3.536-3.537a1.25 1.25 0 000-1.767l-3.536-3.536a1.25 1.25 0 00-1.767 0z"
      ></path>
    </svg>
  );
};
