import { Button, alpha } from "@mui/material";
import { ClaimSvg } from "./ClaimSvg";

export const ClaimBtn = () => {
  return (
    <Button
      variant="contained"
      startIcon={<ClaimSvg />}
      sx={{
        fontSize: 16,
        bgcolor: "#FFBE00",
        color: "#161A2F",
        "&:hover": {
          bgcolor: alpha("#FFBE00", 0.8),
        },
        borderRadius: 10,
        px: 2.5,
      }}
    >
      Click to claim
    </Button>
  );
};
